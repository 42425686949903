<template>
    <div class="uploading-area">
        <input class="uploading-area__input" :accept="acceptedFormats.toString()" type="file"
               @change="$emit('fileLoaded', $event)"/>
        <img src="@/assets/images/cloud-upload.png" alt="">
        <p>Drag and drop files to upload or</p>
        <CButton color="primary">Browse files</CButton>
        <span>⚠ Supported file types: ({{ formats.join(', ') }})</span>
    </div>
</template>

<script>
export default {
    name: "FileUploadingArea",
    props: {
        formats: {
            type: Array,
            default: () => []
        },
    },
    computed: {
        acceptedFormats() {
            return this.formats.map(format => `.${format}`)
        }
    }
}
</script>

<style scoped lang="scss">
.uploading-area {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 320px;
    background: rgb(171 231 250 / 26%);
    border-radius: 16px;
    border: 1px dashed #00437A;
    position: relative;
    padding: 20px;

    &__input {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        opacity: 0;
        cursor: pointer;
    }

    & img {
        margin-bottom: 25px;
    }

    & p {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 12px;
    }

    & span {
        font-size: 12px;
        margin-top: 12px;
        display: block;
        text-align: center;
    }
}
</style>
