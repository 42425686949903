<template>
    <CModal content-class-name="loading-modal" size="lg" :visible="visible">
        <CModalBody>
            <div class="header">
                <span class="title">Uploading 1 item</span>
            </div>
            <div class="body">
                <div class="file py-2">
                    <i class="far fa-file"></i>
                    <span>{{ file.name }}</span>
                    ({{ Number(file.size / 1000).toFixed(2) }}kb)
                    <CSpinner class="spinner" color="primary" size="sm"></CSpinner>
                </div>
            </div>
        </CModalBody>
    </CModal>
</template>

<script>
export default {
    name: 'UploadModalLoader',
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        file: {
            type: File,
            required: true,
        }
    },
}
</script>

<style scoped lang="scss">
.loading-modal {
    overflow: hidden;
    box-shadow: none !important;

    & .modal-body {
        padding: 0 !important;
        border-radius: 0.3em;
        overflow: hidden;
    }

    .header {
        background: #202e39;
        padding: 16px 32px;

        .title {
            font-size: 16px;
            font-weight: 400;
            color: white;
        }
    }


    .body {
        padding: 16px 32px;
        min-width: 400px;
        max-width: 500px;

        .file {
            display: flex;
            align-items: center;
            gap: 10px;

            span {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
            }

            .spinner {
                flex-shrink: 0;
            }
        }
    }
}
</style>
