<template>
    <CModal :visible="visible" backdrop="static" @close="$emit('close')">
        <div class="template-page__modal template-page__modal-basic" :class="modalType.class">
            <CIcon v-if="modalType.icon" class="template-page__modal-icon" :name="modalType.icon"/>
            <div class="template-page__modal-info">
                <h3 v-if="modalType.title" class="template-page__modal-title">{{ modalType.title }}</h3>
                <p v-if="modalType.text" class="template-page__modal-text">{{ modalType.text + additionalText }}</p>
            </div>

            <div class="template-page__modal-body">
                <slot/>
            </div>

            <div class="template-page__modal-buttons">
                <CButton v-if="enableCancelButton" variant="outline" color="primary" @click="$emit('close')">
                    {{ cancelButtonLabel }}
                </CButton>
                <CButton color="primary" @click="$emit('confirm')">{{ confirmButtonLabel }}</CButton>
            </div>
        </div>
    </CModal>
</template>

<script>
const TYPES = {
    'info-acknowledgment': {
        title: 'Requirements',
        text: 'To request an acknowledgment of receipt you have several options. You can upload a .doc or .docx files and add merge fields.  Or you can upload other formats, but merge fields is not supported (At the time).',
        class: 'modal_info-acknowledgment',
        icon: 'cil-file'
    },
    'info-request': {
        title: 'Requirements',
        text: 'To request a document you have several options. You can upload a .doc or .docx files and add merge fields. Or you can upload other formats, but merge fields are not supported (at this time).',
        class: 'modal_info-request',
        icon: 'cil-file'
    },
    'error-file': {
        title: 'Requirements',
        text: 'If you want to request acknowledgement of receipt, you have to upload a file.',
        class: 'modal_error-file',
        icon: 'cil-file'
    },
    'error-format': {
        title: 'File not supported!',
        text: 'Oops, this file format is not recognized yet by our platform. Here is the list of the supported formats: ',
        class: 'modal_error-format',
        icon: 'cil-face-dead'
    },
    'error-size': {
        title: 'File not supported!',
        text: 'Oops, this file size is not supported yet by our platform. Maximum file size - 100 mb.',
        class: 'modal_error-size',
        icon: 'cil-face-dead'
    },
    'error-unexpected': {
        title: 'Oops, something went wrong.',
        text: 'Please, try again.',
        class: 'modal_error-unexpected',
        icon: 'cil-face-dead'
    },
    'status-draft': {
        title: 'Publish your template',
        text: 'Are you sure you want to keep this template as a draft? You need to change the status to “Published” if you are ready to make the template available in the system.',
        class: 'modal_status-draft',
        icon: 'cil-file'
    },
};

export default {
    name: 'TemplateFormModals',
    emits: [
        'close',
        'confirm'
    ],
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: null
        },
        confirmButtonLabel: {
            type: String,
            default: 'Continue'
        },
        cancelButtonLabel: {
            type: String,
            default: 'Cancel'
        },
        enableCancelButton: {
            type: Boolean,
            default: false
        },
        additionalText: {
            type: String,
            default: ''
        }
    },
    computed: {
        modalType() {
            return TYPES[this.type] || null;
        }
    }
}
</script>

<style scoped lang="scss">

</style>
