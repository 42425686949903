export default {
    methods: {
        validateFormatAndSize(file, expectedFormats, expectedSize) {
            return new Promise((resolve, reject) => {
                const fileFormat = file.name.split('.').pop();

                if (!expectedFormats.includes(fileFormat)) {
                    reject('format');
                }

                if (file.size > expectedSize) {
                    reject('size');
                }

                resolve(true);
            });
        }
    }
}
